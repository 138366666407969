$spacing: 8px;

html,
body,
#root {
  height: 100%;
}

/* Layout Utils */
.px, .px-1 {
  padding-left: $spacing;
  padding-right: $spacing;
}

.px-2 {
  padding-left: $spacing * 2;
  padding-right: $spacing * 2;
}

.py, .py-1 {
  padding-top: $spacing;
  padding-bottom: $spacing;
}

.py-2 {
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;
}

.p1 {
  padding: $spacing;
}

.p2 {
  padding: $spacing * 2;
}

.mb-0 {
  margin-bottom: 0;
}

.mb, .mb-1 {
  margin-bottom: $spacing;
}

.mb-2 {
  margin-bottom: $spacing * 2;
}

.mt, .mt-1 {
  margin-top: $spacing;
}

.mt-2 {
  margin-top: $spacing * 2;
}

.my, .my-1 {
  margin-bottom: $spacing;
  margin-top: $spacing;
}

.my-2 {
  margin-bottom: $spacing * 2;
  margin-top: $spacing * 2;
}

.mx, .mx-1 {
  margin-right: $spacing;
  margin-left: $spacing;
}

.mx-2 {
  margin-right: $spacing * 2;
  margin-left: $spacing * 2;
}

.mr, .mr-1 {
  margin-right: $spacing;
}

.position-fixed {
  position: fixed;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.flex {
  display: flex;
}

.row {
  display: flex;
  flex: 1 0 100%;
  flex-direction: row;
}

.row-no-basis {
  display: flex;
  flex: 1 0;
  flex-direction: row;
}

.row-no-grow {
  display: flex;
  flex: 0 0;
  flex-direction: row;
}

.column {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.column-no-grow {
  display: flex;
  flex: 0;
  flex-direction: column;
}

.flex-grow {
  flex: 1
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.d-flex {
  display: flex;
}

.relative {
  position: relative;
}

@mixin center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
}

.center-container {
  @include center-container;
}

.center-container-row {
  @include center-container;
  flex-direction: row;
}

/* /Layout Utils */

.handles-click {
  cursor: pointer;
}

.form-group {
  margin-bottom: 32px;
}

.card-root {
  margin-bottom: $spacing * 2;
  margin-top: $spacing * 2;
  padding: $spacing * 2;
}

.input-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  > div {
    display: flex;
    flex-grow: 1;
  }
}

.MuiFormGroup-root.row {
  display: flex;
  flex-direction: row;
}

.MuiFormControl-root.mb-0 {
  margin-bottom: 0;
}

.form-control-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}

// no spinners on number fields
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

.MuiFormHelperText-root{
  white-space: normal;
}

.MuiTypography-root.disabled{
  opacity: .6;
}

// card media class 4/3 aspect ratio
.media {
  height: 0;
  padding-top: 75%;
}

// move Jira widget
iframe#jsd-widget{
  top: 0;
  left: 20% !important;
 }


 .RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}